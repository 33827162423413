import React, { useEffect } from 'react';
import useDashboardStore from '../../store/dashboardStore';
import { Card, CardBody } from 'reactstrap';

function LeadCount(){
    const count = useDashboardStore(state => state.leadCount);

    useEffect(() => {
        useDashboardStore.getState().fetchLeadCount();
    }, []);

    return(
        <div className="col-lg-12">
        <Card>
            <CardBody>
                <h5 className="text-muted font-weight-normal mt-0" title="Number of Leads">Leads</h5>
                <h3 className="mt-3 mb-3">{count}</h3>
            </CardBody>    
        </Card>    
        </div>
    )
}

export default LeadCount;