import React, { useEffect, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import FailedLeadsModal from './FailedLeadsModal';
import useDashboardStore from '../../store/dashboardStore';

function FailedLeadsDisplay() {

    const [modal, setModal] = useState(false);
    const toggle = () => {
        useDashboardStore.setFailedVendorId('')
        setModal(!modal);
    }
    const failedLeads = useDashboardStore(state => state.failedLeads);
    const failedVendorId = useDashboardStore(state => state.failedVendorId);

    useEffect(() => {
        useDashboardStore.getState().fetchFailedLeads();
    }, []);

    useEffect(() => {
        if(failedVendorId !== ''){
            setModal(true);
        }
    }, [failedVendorId]);


    return(
        <div className="col-xl-6 col-lg-12 order-lg-2 order-xl-1">
        <FailedLeadsModal modal={modal} toggle={toggle}/>
        <Card style={{height: "200px", overflowY: "auto", marginTop: "10px", marginBottom: "30px"}}>
            <CardBody>
            <h5 className="text-muted font-weight-normal mt-0" title="Number of failed leads">Failed Leads</h5>
    
            <Table>
                <tbody>
                    {failedLeads.length > 0 ? (Object.keys(failedLeads).map((items,idx) => (
                        <tr key={idx}>  
                            <td>{failedLeads[items].company_name}</td>
                            <td><a href="#" onClick={e => useDashboardStore.setFailedVendorId(failedLeads[items].vendor_id)}>{failedLeads[items].failed}</a></td>
                        </tr>    
                    ))
                    ): (
                        <tr><td>No failed leads found</td></tr>
                    )}
                </tbody>    
            </Table>   
            </CardBody>
        </Card>  
        </div>  
       ) 
}

export default FailedLeadsDisplay;