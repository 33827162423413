import React, { Fragment, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import useDashboardStore from '../../store/dashboardStore';

function FailedLeadsModal(props){

    const failedVendorId = useDashboardStore(state => state.failedVendorId);

    useEffect(() => {
        useDashboardStore.getState().fetchFailedVendors(failedVendorId);
    }, []);
        

    return(
        <Modal size="xl" isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggleModal}>Failed Leads</ModalHeader>
        <ModalBody>
        <ReactTable
            data={failedVendorId}
            columns={
                [
                {
                    Header: "View",
                    accessor: "view",
                    foldable: false,
                    sortable: false,
                    filterable: false,
                    maxWidth: 50,
                    Cell: function(props){
                        console.log(props)
                        return (
                            <Fragment>
                            <Link to={`/leads/${props.original.lead_id}`}><FontAwesomeIcon icon={['fas', "eye"]} size="lg" style={{marginRight: "20px"}}/></Link>
                            </Fragment>
                        )  
                    }   
                },
        {
            Header: "Lead ID",
            accessor: "lead_id",
            id: "lead_id",
            foldable: true,
            maxWidth: 100,
            style: { 'whiteSpace': 'unset' }
        },
        {
            Header: "Vendor",
            accessor: 'company_name',
            id: "company_name",
            foldable: true,
            maxWidth: 150,
            style: { 'whiteSpace': 'unset' }
        },
        {
            Header: "Buyer",
            accessor: 'buyer_company_name',
            id: "buyer_company_name",
            foldable: true,
            maxWidth: 150,
            style: { 'whiteSpace': 'unset' }
        },
        {
            Header: "Server Response",
            accessor: 'server_response',
            id: "server_response",
            foldable: true,
            style: { 'whiteSpace': 'unset' },
            Cell: function(props){
                return(
                    <pre>{JSON.stringify(JSON.parse(props.value), null, 2)}</pre>
                )
            }  
        },{
            Header: "Date",
            accessor: "timestamp",
            id: "timestamp",
            foldable: true,
            maxWidth: 150,
            style: { 'whiteSpace': 'unset' }
        }
    ]
            }
            loading={props.loading}
            className="-highlight"
            />
        </ModalBody>
      </Modal>
    )
}

export default FailedLeadsModal;