import React from 'react';
import {  Row, Col } from 'reactstrap'
import BuyerCount from './BuyerCount';
import VendorCount from './VendorCount';
import LeadCount from './LeadCount';
import LeadsByCount from './LeadsByCount';
import PendingLeadsDisplay from './PendingLeadsDisplay';
import FailedLeadsDisplay from './FailedLeadsDisplay';
import UnsoldLeadsDisplay from './UnsoldLeadsDisplay';
import DeclinedLeadsDisplay from './DeclinedLeadsDisplay';

function Dashboard(){
    return(
    <Col>
        {/* <FailedLeadsModal/> */}
        <Row>
            {/* <QuickActions /> */}
        </Row>  
        <br />  
        <Row>
            <div className="col-xl-5 col-lg-6">
            <Row>
                <BuyerCount />
                <VendorCount />
            </Row>
            <br />
            <Row>
                <LeadCount/>
            </Row>
            </div>
            <div className="col-xl-7  col-lg-6">
                <LeadsByCount />
            </div>
        </Row>  
        <br />
        <Row>
            <PendingLeadsDisplay/>
            <FailedLeadsDisplay />
            <UnsoldLeadsDisplay />
            <DeclinedLeadsDisplay />
        </Row>  
    </Col>
    )
}

export default Dashboard;