import create from 'zustand';
import { dashboards, buyers, lead, vertical, vendors  } from '../util/db'

const useDashboardStore = create((set) => ({
    modal: false,
    data: [],
    buyerCount: 0,
    vendorCount: 0,
    leadsByType: [],
    leadCount: 0,
    buyer: [],
    verticals: [],
    vendor: [],
    pendingLeads: [],
    failedLeads: [],
    declinedLeads: [],
    unsoldLeads: [],
    failedVendor: [],
    failedVendorId: '',
    fetchBuyerCount: async () => {
        const response = await dashboards('', 'buyer-count')
        set({ buyerCount: response })
    },
    fetchVendorCount: async () => {
        const response = await dashboards('', 'vendor-count')
        set({ vendorCount: response })
    },
    fetchLeadsByType: async () => {
        const response = await dashboards('', 'lead-by-type')
        set({ leadsByType: response })
    },
    fetchLeadCount: async () => {
        const response = await dashboards('', 'lead-count')
        set({ leadCount: response })
    },
    fetchBuyers: async () => {
        const response =  await buyers('', 'search')
        set({ buyer: response && response.items ? response.items : [] })
    },
    soldLeads: async () => {
        const response = await lead('', 'pivot-sold')
        set({ data: response })
    },
    setVerticals: async () => {
        const response = await vertical()
        set({ verticals: response && response.items ? response.items : []})
    },
    setVendors: async () => {
        const response = await vendors('', 'search') 
        set({ vendor: response && response.items ? response.items : []})
    },
    fetchPendingLeads: async () => {
        const response = await dashboards('', 'pending')
        set({ pendingLeads: response })
    },
    fetchFailedLeads: async () => {
        const response = await dashboards('', 'failed')
        set({ failedLeads: response })
    },
    fetchDeclinedLeads: async () => {
        const response = await dashboards('', 'declined')
        set({ declinedLeads: response })
    },
    fetchUnsoldLeads: async () => {
        const response = await dashboards('', 'unsold')
        set({ unsoldLeads: response })
    },
    setFailedVendorId: (id) => {
        set({ failedVendorId: id })
    },
    fetchFailedVendors: async (failedVendorId) => {
        const response = await dashboards('', 'failed-vendor', {
            'vendor_id' : failedVendorId
        })
        set({ failedVendor: response })
    }
}))
export default useDashboardStore;