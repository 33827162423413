import React, { useEffect, useState } from 'react';
import useDashboardStore from '../../store/dashboardStore';
import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";

function VendorCount() {
    const count  = useDashboardStore(state => state.vendorCount);
    const vendors = useDashboardStore(state => state.vendors);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
        useDashboardStore.getState().fetchVendorCount();
        useDashboardStore.getState().setVendors();
    }, []);
    return(
        <div className="col-lg-6">
        <Card>
            <CardBody>
            <div className="dropdown float-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                >
                <div className="mdi-dots-vertical"></div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        <Link to="/vendor">All vendors</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <Link to="/vendor/new">Add a new vendor</Link>
                    </DropdownItem>
                    <DropdownItem>
                    <CSVLink 
                        data={vendors || []}
                        filename={`vendor-dataexport${Date.now()}.csv`}
                        target="_blank"
                        style={{marginRight: "20px"}}
                    >
                        Export Vendors
                    </CSVLink>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </div>
                <h5 className="text-muted font-weight-normal mt-0" title="Number of Vendors">Vendors</h5>
                <h3 className="mt-3 mb-3">{count}</h3>
            </CardBody>    
        </Card>    
        </div>
    )
}

export default VendorCount;