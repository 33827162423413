import React,{ useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import useDashboardStore from '../../store/dashboardStore';

function PendingLeadsDisplay(){

    const pendingLeads = useDashboardStore(state => state.pendingLeads);

    useEffect(() => {
        useDashboardStore.getState().fetchPendingLeads();
    }, []);


    return(
     <div className="col-xl-6 col-lg-12 order-lg-2 order-xl-1">
     <Card className="pendingDisplay" style={{height: "200px", overflowY: "auto", marginTop: "10px", marginBottom: "30px"}}>
         <CardBody>
         <h5 className="text-muted font-weight-normal mt-0" title="Number of pending leads">Pending Leads</h5>
 
         <Table>
             <tbody>
                 {pendingLeads.length > 0 ? (Object.keys(pendingLeads).map((items,idx) => (
                     <tr key={idx}>  
                         <td>{pendingLeads[items].company}</td>
                         <td><Link to={`/leads?status=pending&vendor=${pendingLeads[items].company}`}>{pendingLeads[items].pending}</Link></td>
                     </tr>    
                 ))
                 ): (
                     <tr><td>No pending leads found</td></tr>
                 )}
             </tbody>    
         </Table>   
         </CardBody>
     </Card>  
     </div>  
    ) 
 }

 export default PendingLeadsDisplay;