import React, { useEffect } from 'react';
import moment from 'moment';
import { Card, CardBody, Table } from 'reactstrap';
import useDashboardStore from '../../store/dashboardStore';
import { Link } from 'react-router-dom';

function DeclinedLeadsDisplay() {
    
        const declinedLeads = useDashboardStore(state => state.declinedLeads);
    
        useEffect(() => {
            useDashboardStore.getState().fetchDeclinedLeads();
        }, []);

        const updatedDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    
        return(
            <div className="col-xl-6 col-lg-12 order-lg-2 order-xl-1">
            <Card className="pendingDisplay" style={{height: "200px", overflowY: "auto", marginTop: "10px", marginBottom: "30px"}}>
                <CardBody>
                    <h5 className="text-muted font-weight-normal mt-0" title="Number of pending leads">Declined Leads</h5>
            
                    <Table>
                        <tbody>
                            {declinedLeads.length > 0 ? (Object.keys(declinedLeads).map((items,idx) => (
                                <tr key={idx}>  
                                    <td>{declinedLeads[items].company}</td>
                                    <td><Link to={`/leads?status=declined&vendor=${declinedLeads[items].company}&end_date=${moment().format('YYYY-MM-DD')}&start_date=${updatedDate}`}>{declinedLeads[items].declined}</Link></td>
                                </tr>    
                            ))
                            ): (
                                <tr><td>No declined leads found</td></tr>
                            )}
                        </tbody>    
                    </Table>   
                </CardBody>
            </Card>  
            </div>  
           ) 
    }

export default DeclinedLeadsDisplay;