import React, { useEffect } from 'react';
import useDashboardStore from '../../store/dashboardStore';
import { Card, CardBody } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';


function LeadsByCount(){
    const type = useDashboardStore(state => state.leadsByType);

    useEffect(() => {
        useDashboardStore.getState().fetchLeadsByType();
    }, []);

    const BackGroundColors = () => [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ]
    


    return(
  
    <Card>
        <CardBody>
            { type && type.length > 0 ?
             <Doughnut 
                data={{
                    labels: Object.keys(type).map(items => ( type[items].status )),
                    datasets: [{
                      data: Object.keys(type).map(items => ( type[items].id )),
                      backgroundColor: BackGroundColors(),
                    }]
                }} 
            /> 
            : "Loading...."}
        </CardBody>    
    </Card>    
    )
}

export default LeadsByCount;