import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Card, CardBody, Table } from 'reactstrap';

import useDashboardStore from '../../store/dashboardStore';

function UnsoldLeadsDisplay() {

    const unsoldLeads = useDashboardStore(state => state.unsoldLeads);

    useEffect(() => {
        useDashboardStore.getState().fetchUnsoldLeads();
    }, []);

    const updatedDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    

    return(
        <div className="col-xl-6 col-lg-12 order-lg-2 order-xl-1">
        <Card className="pendingDisplay" style={{height: "200px", overflowY: "auto", marginTop: "10px", marginBottom: "30px"}}>
            <CardBody>
            <h5 className="text-muted font-weight-normal mt-0" title="Number of pending leads">Unsold Leads</h5>
    
            <Table>
                <tbody>
                    {unsoldLeads.length > 0 ? (Object.keys(unsoldLeads).map((items,idx) => (
                        <tr key={idx}>  
                            <td>{unsoldLeads[items].company}</td>
                            <td><Link to={`/leads?status=not+sold&vendor=${unsoldLeads[items].company}&end_date=${moment().format('YYYY-MM-DD')}&start_date=${updatedDate}`}>{unsoldLeads[items].unsold}</Link></td>
                        </tr>    
                    ))
                    ): (
                        <tr><td>No unsold leads found</td></tr>
                    )}
                </tbody>    
            </Table>   
            </CardBody>
        </Card>  
        </div>  
       ) 
}

export default UnsoldLeadsDisplay;