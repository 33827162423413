import React,{useEffect, useState}  from 'react';
import useDashboardStore from '../../store/dashboardStore';
import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";

function BuyerCount() {
    const count  = useDashboardStore(state => state.buyerCount);
    const buyers = useDashboardStore(state => state.buyer);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(() => {
        useDashboardStore.getState().fetchBuyerCount();
        useDashboardStore.getState().fetchBuyers();
    }, []);

    return (
        <div className="col-lg-6">
        <Card>
            <CardBody>
            <div className="dropdown float-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                >
                <div className="mdi-dots-vertical"></div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem><Link to="/buyer">All buyers</Link></DropdownItem>
                    <DropdownItem><Link to="/buyer/new">Add a new buyer</Link></DropdownItem>
                    <DropdownItem>
                    <CSVLink 
                        data={buyers|| []}
                        filename={`buyer-dataexport${Date.now()}.csv`}
                        target="_blank"
                        style={{marginRight: "20px"}}
                    >
                        Export buyers
                    </CSVLink>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </div>
                <h5 className="text-muted font-weight-normal mt-0" title="Number of Buyers">Buyers</h5>
                <h3 className="mt-3 mb-3">{count}</h3>
            </CardBody>    
        </Card>    
        </div>
    )
}

export default BuyerCount;