import React, { useState } from 'react'
import { Button, Alert } from 'reactstrap'
import ReactDataGrid from "react-data-grid";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DisplayLeads(props){
  const [fields, setFields] = useState([])
    
  // 1) Gather all unique keys from all leads
  // props.leads is presumably an object keyed by lead_id, so we'll iterate over its values
  const allKeysSet = new Set();
  const leadsArray = Object.keys(props.leads).map(key => props.leads[key]);
  leadsArray.forEach(lead => {
    Object.keys(lead).forEach(fieldName => allKeysSet.add(fieldName));
  });

  // Convert to an array of field names
  const allColumns = Array.from(allKeysSet);

  // 2) Build CSV data:
  // - first row: column headers (array of strings)
  // - subsequent rows: values for each lead in the same column order
  const headerRow = allColumns; 
  const dataRows = leadsArray.map(lead =>
    allColumns.map(col => (lead[col] === null || lead[col] === undefined) ? "NULL" : lead[col])
  );
  const csvData = [headerRow, ...dataRows];

  // 3) Build columns for ReactDataGrid
  const gridColumns = allColumns.map(colName => ({
    key: colName,
    name: colName,
    sortable: true,
    filterable: true,
    width: 160
  }));

  // 4) Sort logic
  const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
      return 0;
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  // 5) By default, data for the grid is simply leadsArray
  // If fields is empty, we use leadsArray; if fields is non-empty (after sorting), we use fields
  const gridData = fields.length ? fields : leadsArray;

  // 6) Example of cell actions if needed
  const getCellActions = (column, row) => {
    const cellActions = {
      lead_id: [{
        icon: <FontAwesomeIcon icon={['fas', "eye"]} size="lg" style={{marginRight: "20px"}}/>,
        callback: () => {
          props.history.push(`/leads/${row.lead_id}`)
        }
      }]
    };
    return cellActions[column.key];
  };

  return (
    <div>
      <div style={{margin: "20px"}}>
        <CSVLink
          data={csvData}  // use the consistent CSV array
          filename={`leads-dataexport-${Date.now()}.csv`}
          className="btn btn-primary"
          target="_blank"
          style={{marginRight: "20px"}}
        >
          Export Leads
        </CSVLink>{' '}

        <Button color="success" onClick={e => props.toggleShowLeads()}>Close</Button>
      </div>   
      
      <div style={{margin: "20px"}}>
        {props.loading
          ? <Alert color="warning">Loading data.  One moment...</Alert>
          : <p>Displaying {gridData.length} leads</p>
        }
      </div>
      <br />

      <div>
        <ReactDataGrid
          columns={gridColumns}
          rowGetter={i => gridData[i]}
          rowsCount={gridData.length}
          getCellActions={getCellActions}
          onGridSort={(sortColumn, sortDirection) => {
            setFields(sortRows(gridData, sortColumn, sortDirection));
          }}
        />
      </div>     
    </div>
  )
}

export default DisplayLeads
